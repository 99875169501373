import React, { useContext, useEffect, useState } from 'react';
import blue from "../../assets/backgrounds/blue.jpg";
import red from "../../assets/backgrounds/red.jpg";
import { AppContext } from "../../context/app.context";
import styles from "./Card.module.scss";

export interface ICard {
	name: string;
	url: string;
}

export const Card: React.FC<ICard> = (props) => {
	const { player } = useContext(AppContext);
	const { name, url } = props;
	const [selected, setSelected] = useState(false);

	useEffect(() => {
		if (!player)
			setSelected(false);
	}, [player]);

	return (
		<div
			className={styles.root}
			onClick={() => player ? setSelected(true) : null}
		>
			<div className={styles.cardInner + (selected ? (" " + styles.selected) : "")}>
				<div className={styles.front}>
					<img src={url} alt={name} className={styles.cardImage} />
					<div className={styles.title}>{name}</div>
				</div>
				<div className={styles.back} style={{ backgroundImage: `url(${player === 1 ? blue : red})` }}>{"Guess Who?"}</div>
			</div>
		</div>
	);
}

export default Card;