import { Button, CircularProgress, Typography } from "@material-ui/core";
import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/app.context";
import styles from './game.module.scss';

export interface INotFoundProps {

}

export const NotFound: React.FC<INotFoundProps> = (props) => {
	const history = useHistory();
	const { loading } = useContext(AppContext);
	return (
		<>
			{
				loading
					? <>
						<Typography style={{ marginBottom: "2rem" }}>Loading, please wait...</Typography>
						<CircularProgress color="secondary" style={{ marginBottom: "2rem" }} />
					</>
					: <div className={styles.notfound}>
						<Typography variant={"h3"} style={{ marginBottom: "2rem" }}>{"Game not found"}</Typography>
					</div>
			}
			<Button
				variant={"outlined"}
				color={"default"}
				onClick={() => history.push("/")}
			>
				{"Back"}
			</Button>
		</>
	);
}

export default NotFound;
