import React, { useContext } from 'react';
import { AppContext } from "../../context/app.context";
import Board from "../board/board";
import Player from "../player/player";
import Share from "../share/share";
import styles from './game.module.scss';
import NotFound from "./notFound";

export interface IGameProps {

}

export const Game: React.FC<IGameProps> = (props) => {
	const { game, share } = useContext(AppContext);

	return (
		<div className={styles.game} style={!game ? { gridTemplateColumns: "1fr", alignContent: "center", justifyItems: "center" } : {}}>
			{
				game
					? <>
						<Board />
						<Player />
					</>
					: <NotFound />
			}
			{
				share &&
				<Share />
			}
		</div>
	);
}

export default Game;
