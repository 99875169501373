import { firestore } from "firebase";

export enum CardPack {
	SIMPSONS = "SIMPSONS",
	BLUEY = "BLUEY"
}

export class Game {
	public id: string = "";
	public cardPack: CardPack = CardPack.SIMPSONS;
	public player1Card: number = -1;
	public player2Card: number = -1;
	public created: firestore.Timestamp | null = null;

	constructor(item?: any) {
		if (!item) return this;

		this.id = item.id;
		this.cardPack = item.cardPack;
		this.player1Card = item.player1Card;
		this.player2Card = item.player2Card;
		this.created = item.created;
	}
}