import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from "@material-ui/core";
import NewIcon from "@material-ui/icons/Add";
import CloseButton from "@material-ui/icons/Close";
import MoreIcon from "@material-ui/icons/MoreVert";
import RefreshButton from "@material-ui/icons/Refresh";
import ShareIcon from "@material-ui/icons/Share";
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/app.context";
import { CardData } from "../../models/card.model";
import { Confirm } from "../confirm/confirm";
import styles from './player.module.scss';

export interface IPlayerProps {

}

export const Player: React.FC<IPlayerProps> = (props) => {
	const { loading, game, cardSet, setShare, restartGame, player, setPlayer } = useContext(AppContext);
	let [menuOpen, setMenuOpen] = useState(false);
	let [selectedCard, setSelectedCard] = useState<CardData>();
	const history = useHistory();

	useEffect(() => {
		let playerCard: number | undefined = undefined;
		if (player === 1) {
			playerCard = game!.player1Card;
		}
		if (player === 2) {
			playerCard = game!.player2Card;
		}
		if (playerCard !== undefined) {
			let card = cardSet?.pack[playerCard];
			setSelectedCard(card);
		}
	}, [player, cardSet, game]);

	return (
		<>
			<div className={styles.player}>
				<div className={styles.buttons}>
					<Tooltip
						arrow
						title={"Menu"}
					>
						<IconButton
							onClick={() => setMenuOpen(true)}
						>
							<MoreIcon />
						</IconButton>
					</Tooltip>
				</div>
				{
					player
						? <>
							<img src={selectedCard?.url} alt={selectedCard?.name} className={styles.playerCard} />
							<div className={styles.playerName}>{selectedCard?.name}</div>
						</>
						: <div style={{ display: "grid", gridGap: "1rem" }}>
							<Typography>Choose Player:</Typography>
							<Button
								variant={"outlined"}
								onClick={() => setPlayer(1)}
							>
								{"Player 1"}
							</Button>
							<Button
								variant={"outlined"}
								onClick={() => setPlayer(2)}
							>
								{"Player 2"}
							</Button>
						</div>
				}
			</div>

			{
				menuOpen &&
				< Dialog onClose={() => setMenuOpen(false)} open={true}>
					<DialogTitle >Menu</DialogTitle>
					<DialogContent className={styles.content}>
						<Button
							disabled={loading}
							variant={"outlined"}
							onClick={() => setShare(true)}
							startIcon={<ShareIcon />}
						>
							{"Share Link"}
						</Button>
						<Button
							disabled={loading}
							variant={"outlined"}
							onClick={async () => await Confirm("Are you sure you want to reset the board?") ? window.location.reload() : null}
							startIcon={<RefreshButton />}
						>
							{"Reset Board"}
						</Button>
						<Button
							disabled={loading}
							variant={"outlined"}
							onClick={async () => {
								if (await Confirm("Are you sure you want to start a new game?")) {
									await restartGame();
									setMenuOpen(false);
									setPlayer(undefined);
								}
							}}
							startIcon={<NewIcon />}
						>
							{"Start a new game"}
						</Button>
						<Button
							disabled={loading}
							variant={"outlined"}
							onClick={async () => await Confirm("Are you sure you want to leave the game?") ? history.push("/") : null}
							startIcon={<CloseButton />}
						>
							{"Leave Game"}
						</Button>
						{
							loading &&
							<CircularProgress color={"secondary"} />
						}
					</DialogContent>
				</Dialog >
			}
		</>
	);
}

export default Player;
