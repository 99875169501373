import { CardData } from "../models/card.model";
import { CardPack } from "../models/game.model";
import Bandit from "./bluey/Bandit.png";
import Bentley from "./bluey/Bentley.png";
import Bingo from "./bluey/Bingo.png";
import Bluey from "./bluey/Bluey.png";
import Buddy from "./bluey/Buddy.png";
import Calypso from "./bluey/Calypso.png";
import Chilli from "./bluey/Chilli.png";
import Chloe from "./bluey/Chloe.png";
import Coco from "./bluey/Coco.png";
import Honey from "./bluey/Honey.png";
import Indy from "./bluey/Indy.png";
import Judo from "./bluey/Judo.png";
import Lucky from "./bluey/Lucky.png";
import LuckyDad from "./bluey/LuckyDad.png";
import Mackenzie from "./bluey/Mackenzie.png";
import Missy from "./bluey/Missy.png";
import Muffin from "./bluey/Muffin.png";
import Nana from "./bluey/Nana.png";
import Pretzel from "./bluey/Pretzel.png";
import Rusty from "./bluey/Rusty.png";
import Snickers from "./bluey/Snickers.png";
import Socks from "./bluey/Socks.png";
import UncleStripe from "./bluey/UncleStripe.png";
import Winton from "./bluey/Winton.png";
import ApuNahasapeemapetilon from "./simpsons/Apu Nahasapeemapetilon.png";
import BarneyGumble from "./simpsons/Barney Gumble.png";
import BartSimpson from "./simpsons/Bart Simpson.png";
import CharlesMontgomeryBurns from "./simpsons/Charles Montgomery Burns.png";
import ChiefWiggum from "./simpsons/Chief Wiggum.png";
import ComicBookGuy from "./simpsons/Comic Book Guy.png";
import DrJuliusHibbert from "./simpsons/Dr Julius Hibbert.png";
import DrNickRiviera from "./simpsons/Dr Nick Riviera.png";
import GroundskeeperWillie from "./simpsons/Groundskeeper Willie.png";
import HomerSimpson from "./simpsons/Homer Simpson.png";
import KentBrockman from "./simpsons/Kent Brockman.png";
import KrusttheClown from "./simpsons/Krust the Clown.png";
import LisaSimpson from "./simpsons/Lisa Simpson.png";
import MaggieSimpson from "./simpsons/Maggie Simpson.png";
import MargeSimpson from "./simpsons/Marge Simpson.png";
import MilhouseVanHouten from "./simpsons/Milhouse Van Houten.png";
import MoeSzyslak from "./simpsons/Moe Szyslak.png";
import NedFlanders from "./simpsons/Ned Flanders.png";
import OttoMann from "./simpsons/Otto Mann.png";
import PrincipalSkinner from "./simpsons/Principal Skinner.png";
import ProfessorFrink from "./simpsons/Professor Frink.png";
import ReverendLovejoy from "./simpsons/Reverend Lovejoy.png";
import SideshowBob from "./simpsons/Sideshow Bob.png";
import WaylonSmithers from "./simpsons/Waylon Smithers.png";

export const SimpsonsPack: CardData[] = [
	{ name: "Apu Nahasapeemapetilon", url: ApuNahasapeemapetilon },
	{ name: "Barney Gumble", url: BarneyGumble },
	{ name: "Bart Simpson", url: BartSimpson },
	{ name: "Charles Montgomery Burns", url: CharlesMontgomeryBurns },
	{ name: "Chief Wiggum", url: ChiefWiggum },
	{ name: "Comic Book Guy", url: ComicBookGuy },
	{ name: "Dr Julius Hibbert", url: DrJuliusHibbert },
	{ name: "Dr Nick Riviera", url: DrNickRiviera },
	{ name: "Groundskeeper Willie", url: GroundskeeperWillie },
	{ name: "Homer Simpson", url: HomerSimpson },
	{ name: "Kent Brockman", url: KentBrockman },
	{ name: "Krust the Clown", url: KrusttheClown },
	{ name: "Lisa Simpson", url: LisaSimpson },
	{ name: "Maggie Simpson", url: MaggieSimpson },
	{ name: "Marge Simpson", url: MargeSimpson },
	{ name: "Milhouse Van Houten", url: MilhouseVanHouten },
	{ name: "Moe Szyslak", url: MoeSzyslak },
	{ name: "Ned Flanders", url: NedFlanders },
	{ name: "Otto Mann", url: OttoMann },
	{ name: "Principal Skinner", url: PrincipalSkinner },
	{ name: "Professor Frink", url: ProfessorFrink },
	{ name: "Reverend Lovejoy", url: ReverendLovejoy },
	{ name: "Sideshow Bob", url: SideshowBob },
	{ name: "Waylon Smithers", url: WaylonSmithers },
];

export const BlueyPack: CardData[] = [
	{ name: "Bandit (Dad)", url: Bandit },
	{ name: "Bentley", url: Bentley },
	{ name: "Bingo", url: Bingo },
	{ name: "Bluey", url: Bluey },
	{ name: "Buddy", url: Buddy },
	{ name: "Calypso", url: Calypso },
	{ name: "Chilli (Mum)", url: Chilli },
	{ name: "Chloe", url: Chloe },
	{ name: "Coco", url: Coco },
	{ name: "Honey", url: Honey },
	{ name: "Indy", url: Indy },
	{ name: "Judo", url: Judo },
	{ name: "Lucky", url: Lucky },
	{ name: "Lucky's Dad", url: LuckyDad },
	{ name: "Mackenzie", url: Mackenzie },
	{ name: "Missy", url: Missy },
	{ name: "Muffin", url: Muffin },
	{ name: "Nana", url: Nana },
	{ name: "Pretzel", url: Pretzel },
	{ name: "Rusty", url: Rusty },
	{ name: "Snickers", url: Snickers },
	{ name: "Socks", url: Socks },
	{ name: "Uncle Stripe", url: UncleStripe },
	{ name: "Winton", url: Winton },
];

export interface CardSet {
	name: string;
	pack: CardData[];
}

export const CardSets: Record<keyof typeof CardPack, CardSet> = {
	SIMPSONS: { name: "The Simpsons", pack: SimpsonsPack },
	BLUEY: { name: "Bluey", pack: BlueyPack },
};