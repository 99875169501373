import { Button, createMuiTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ThemeProvider } from "@material-ui/core";
import React, { useEffect, useRef } from 'react';
import ReactDOM from "react-dom";
import styles from "./confirm.module.scss";

const theme = createMuiTheme({
	palette: {
		type: "dark"
	}
});

enum AlertType {
	ALERT,
	CONFIRM
}

export interface IAlertProps {
	message: string;
	title: string;
	type: AlertType;
}

const rootID = "alert-dialog";
let returnResponse: (value: boolean) => void;

const AlertRoot: React.FC<IAlertProps> = (props) => {
	const { message, title, type } = props;
	const root = useRef<HTMLDivElement | null>();

	useEffect(() => {
		let div = document.getElementById(rootID) as HTMLDivElement;
		root.current = div;
	}, [])

	function Close() {
		root.current?.remove();
	}

	function Confirm() {
		if (type === AlertType.CONFIRM) {
			returnResponse(true);
		}
		Close();
	}

	function Cancel() {
		if (type === AlertType.CONFIRM) {
			returnResponse(false);
		}
		Close();
	}

	return (
		<ThemeProvider theme={theme}>
			<Dialog
				onClose={() => Cancel()}
				open={true}
				disablePortal={true}
			>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent className={styles.content}>
					<DialogContentText style={{ whiteSpace: "pre-wrap" }}>{message}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color={"secondary"}
						onClick={() => Confirm()}
					>
						{"OK"}
					</Button>
					{
						type === AlertType.CONFIRM &&
						<Button
							onClick={() => Cancel()}
						>
							{"Cancel"}
						</Button>
					}
				</DialogActions>
			</Dialog>
		</ThemeProvider>
	);
}

function Create(message: string, title: string, type: AlertType = AlertType.ALERT) {
	let div = document.getElementById(rootID);
	if (!div) {
		div = document.createElement("div");
		div.id = rootID;
		document.body.appendChild(div);
	}

	ReactDOM.render(
		<AlertRoot
			message={message}
			title={title}
			type={type}
		/>, div
	);
}

export function Confirm(message: string, title: string = "Confirm") {
	Create(message, title, AlertType.CONFIRM);
	return new Promise<boolean>(resolve => {
		returnResponse = resolve;
	});
}

export function Alert(message: string, title: string = "Alert") {
	Create(message, title, AlertType.ALERT);
}