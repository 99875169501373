import React from 'react';
import { Route, Switch } from "react-router-dom";
import styles from "./App.module.scss";
import Game from "./components/game/game";
import Home from "./components/home/home";
import AppProvider from "./context/app.context";

export interface IAppProps {

}

export const AppRoot: React.FC<IAppProps> = (props) => {
	return (
		<div className={styles.App}>
			<Switch>
				<Route exact path={"/"}><Home /></Route>
				<Route path={"/game"}><Game /></Route>
			</Switch>
		</div>
	);
}

export const App: React.FC<IAppProps> = (props) => {
	return (
		<AppProvider {...props}>
			<AppRoot {...props} />
		</AppProvider>
	);
}

export default App;