import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from "../../context/app.context";
import { CardData } from "../../models/card.model";
import Card from "../card/Card";
import styles from './board.module.scss';

export interface IBoardProps {

}

export const Board: React.FC<IBoardProps> = (props) => {
	const { game, cardSet } = useContext(AppContext);
	const [cards, setCards] = useState<CardData[]>([]);

	useEffect(() => {
		if (cardSet)
			setCards([...cardSet.pack]);
	}, [cardSet, game]);

	return (
		<div className={styles.board}>
			{
				game && cardSet &&
				(cards.map((item, index) =>
					<Card
						key={index}
						name={item.name}
						url={item.url}
					/>
				))
			}
		</div >
	);
}

export default Board;
