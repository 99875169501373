import { Dialog, DialogContent, DialogTitle, IconButton, TextField, Tooltip } from "@material-ui/core";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import ShareIcon from "@material-ui/icons/Share";
import React, { useContext, useRef, useState } from 'react';
import { AppContext } from "../../context/app.context";

type ShareData = {
	title?: string;
	text?: string;
	url?: string;
};

interface Navigator {
	share?: (data?: ShareData) => Promise<void>;
}

export const Share: React.FC = () => {
	const { share, setShare, game } = useContext(AppContext);
	const ref = useRef<HTMLInputElement | null>(null);
	let nav = (navigator as Navigator);
	const [text, setText] = useState("Copy Link");
	const [open, setOpen] = useState(false);

	function openTooltip() {
		setOpen(true);
		setTimeout(() => {
			setText("Copy Link");
			setOpen(false);
		}, 5000);
	}

	function copyLink() {
		try {
			ref.current?.select();
			document.execCommand("copy");
			openTooltip();
			setText("Copied!");
		} catch (e) {
			openTooltip();
			setText("Failed to copy text");
		}
	}

	async function shareLink() {
		if (nav.share) {
			try {
				await nav.share({
					title: "Guess Who?",
					text: "Join a game of Guess Who?",
					url: window.location.href
				});
			} catch (e) {
				setText((e as Error).message);
			}
		}
	}

	return (
		<Dialog onClose={() => setShare(false)} open={share}>
			<DialogTitle >Share Link</DialogTitle>
			<DialogContent style={{ textAlign: "center", padding: "2rem" }}>
				<TextField
					variant={"outlined"}
					label={"Link"}
					defaultValue={window.location.origin + "/game?gameId=" + game?.id}
					inputProps={{
						readOnly: true,
						ref: ref
					}}
				/>
				<Tooltip
					arrow
					placement={"top"}
					title={text}
					open={open}
					onOpen={() => openTooltip()}
				>
					<IconButton onClick={() => copyLink()}>
						<CopyIcon />
					</IconButton>
				</Tooltip>
				<Tooltip
					title={"Share Link"}
					arrow
					placement={"top"}
				>
					<IconButton onClick={() => shareLink()}>
						<ShareIcon />
					</IconButton>
				</Tooltip>
			</DialogContent>
		</Dialog>
	);
}

export default Share;
