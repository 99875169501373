import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, TextField, Tooltip, Typography } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/HelpOutline";
import React, { useContext, useState } from 'react';
import { useHistory } from "react-router-dom";
import version from "../../../package.json";
import { CardSets } from "../../assets/images";
import logo from "../../assets/logo.png";
import { AppContext } from "../../context/app.context";
import InstallPrompt from "../../InstallPrompt/InstallPrompt";
import { CardPack } from "../../models/game.model";
import styles from './home.module.scss';

export interface IHomeProps {

}

export const Home: React.FC<IHomeProps> = (props) => {
	const history = useHistory();
	const { createNewGame, getGameById, loading } = useContext(AppContext);
	let [text, setText] = useState("");
	let [helpOpen, setHelpOpen] = useState(false);
	let [selectCardSetOpen, setSelectCardSetOpen] = useState(false);

	function navigate() {
		text = text.trim();
		if (text.indexOf("gameId=") !== -1) {
			text = text.split("gameId=")[1];
		}
		getGameById(text);
		history.push("/game?gameId=" + text);
	}

	return (
		<div className={styles.home}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<img src={logo} alt={"logo"} style={{ width: "5rem", margin: "1rem" }} />
				<Typography variant={"h3"} style={{
					color: "#fffd"
				}}>
					{"Guess Who?"}
				</Typography>
			</div>
			{
				loading
					? <CircularProgress />
					: <Button
						disabled={loading}
						color={"secondary"}
						variant={"outlined"}
						onClick={() => setSelectCardSetOpen(true)}
					>
						{"New Game"}
					</Button>
			}
			<div className={styles.join}>
				<TextField
					disabled={loading}
					variant={"outlined"}
					label={"Game Id or Link"}
					value={text}
					onChange={(e) => setText(e.target.value)}
				/>
				<Button
					style={{ marginLeft: "1rem" }}
					variant={"outlined"}
					color={"secondary"}
					onClick={() => navigate()}
					disabled={loading || text.length === 0}
				>
					{"Join"}
				</Button>
			</div>
			<Tooltip
				arrow
				title={"Info about the game"}
			>
				<IconButton
					disabled={loading}
					onClick={() => setHelpOpen(!helpOpen)}
				>
					<HelpIcon style={{ color: "#fffa" }} />
				</IconButton>
			</Tooltip>
			<Typography variant={"caption"} style={{ color: "#fff4" }}>{version.version}</Typography>
			<InstallPrompt />
			{
				helpOpen &&
				<Dialog onClose={() => setHelpOpen(false)} open={true}>
					<DialogTitle >Help</DialogTitle>
					<DialogContent className={styles.content}>
						<Typography>This is a web version of the classic board game <a href="https://winning-moves.com/product/guesswho.asp" style={{ color: "white" }}>Guess Who?</a></Typography>
						<Typography>Start a new game, then share the link with your friends. There are extra sharing options in the menu in game.</Typography>
						<Typography>If you receive a link or Game ID from a friend, you can open it directly or paste it into the field on the home page to join their game.</Typography>
						<Typography>This version is best played over alongside a group video/voice chat.</Typography>
						<Typography>Original rules <a href={"https://www.hasbro.com/common/instruct/GuessWho.PDF"} style={{ color: "white" }}>here</a>.</Typography>
					</DialogContent>
				</Dialog >
			}
			{
				selectCardSetOpen &&
				<Dialog onClose={() => setSelectCardSetOpen(false)} open={true}>
					<DialogTitle >Select Card Set</DialogTitle>
					<DialogContent className={styles.content}>
						{
							Object.keys(CardPack).map((item, index) =>
								<Button
									key={index}
									onClick={() => createNewGame(item as CardPack)}
								>
									{CardSets[item as CardPack].name}
								</Button>
							)
						}
						{
							loading &&
							<CircularProgress color={"secondary"} />
						}
					</DialogContent>
				</Dialog >
			}
		</div>
	);
}

export default Home;
