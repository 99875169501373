import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IAppProps } from "../App";
import { CardSet, CardSets } from "../assets/images";
import { getGameIdFromURL } from "../helpers/app.helpers";
import { CardPack, Game } from "../models/game.model";

firebase.initializeApp({
	apiKey: "AIzaSyCVcGBlojJfkFa3SnMcX80wNzQRYGR41j8",
	authDomain: "pwa-projects-95b01.firebaseapp.com",
	databaseURL: "https://pwa-projects-95b01.firebaseio.com",
	projectId: "pwa-projects-95b01",
	storageBucket: "pwa-projects-95b01.appspot.com",
	messagingSenderId: "761598636659",
	appId: "1:761598636659:web:c18049aaa32d71c0a9f006",
	measurementId: "G-K3NC829Z6Z"
});

const functions = firebase.functions();
const CreateGame = functions.httpsCallable('GuessWhoCreateGame');
const GetGame = functions.httpsCallable('GuessWhoGetGame');
const RestartGame = functions.httpsCallable('GuessWhoRestartGame');

export interface IAppContext {
	loading: boolean;
	setLoading(loading: boolean): void;
	game: Game | null;
	cardSet: CardSet | undefined;
	player: 1 | 2 | undefined;
	setPlayer(player: 1 | 2 | undefined): void;
	getGameById(id: string): void;
	createNewGame(cardPack: CardPack): Promise<void>;
	restartGame(): Promise<void>;
	share: boolean;
	setShare(open: boolean): void;
}

export const AppContext = React.createContext<IAppContext>(
	{ ...{} as IAppContext }
);

const AppProvider: React.FC<IAppProps> = (props) => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	let [share, setShare] = useState(false);
	const [game, setGame] = useState<Game | null>(null);
	const [cardSet, setCardSet] = useState<CardSet>();
	const [player, setPlayer] = useState<1 | 2>();
	console.log(cardSet);

	const getGameById = useCallback(async (id: string) => {
		setLoading(true);
		try {
			setGame(null);
			let result = await GetGame({ id });
			if (result.data !== null) {
				let currentGame = new Game(result.data);
				setGame({ ...currentGame });
				getCardData(currentGame);
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	}, []);

	useEffect(() => {
		let id = getGameIdFromURL();
		if (id) {
			getGameById(id);
		}
	}, [getGameById]);

	async function createNewGame(cardPack: CardPack) {
		setLoading(true);
		try {
			let result = await CreateGame({ cardPack });
			let newGame = new Game(result.data);
			getCardData(newGame);
			setGame({ ...newGame });
			history.push("/game?gameId=" + newGame.id);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	}

	async function restartGame() {
		setLoading(true);
		if (game) {
			let result = await RestartGame({ ...game });
			let currentGame = new Game(result.data);
			setGame({ ...currentGame });
		}
		setLoading(false);
	}

	async function getCardData(currentGame: Game) {
		let currentCardData = CardSets[currentGame.cardPack];
		setCardSet({ ...currentCardData });
	}

	return (
		<AppContext.Provider value={{
			...props,
			loading,
			setLoading: (loading) => setLoading(loading),
			game,
			cardSet,
			player,
			setPlayer: (p) => setPlayer(p),
			getGameById: (id) => getGameById(id),
			createNewGame: (cardPack) => createNewGame(cardPack),
			restartGame: () => restartGame(),
			share,
			setShare: (open) => setShare(open),
		}}>
			{props.children}
		</AppContext.Provider>
	);
}

export default AppProvider;